<template>
  <v-container
    id="register"
    fluid
    tag="section"
    class="pt-0 mt-0 light-blue lighten-5"
  >
    <!-- <v-overlay
      v-if="loading"
      class="align-center justify-center overlay-css"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay> -->
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="orange"
    >
      {{ errorMessage }}
      <v-btn
        color="white"
        text
        @click="resetErrorResponse"
      >
        {{ $t('loginPage.close') }}
      </v-btn>
    </v-snackbar>
    <v-row
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="10"
        md="9"
        lg="8"
        xl="6"
        class="px-1 d-flex justify-center"
      >
        <v-card
          class="d-inline-flex"
          elevation="1"
          light
          width="100%"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
            >
              <v-row
                no-gutters
                class="d-flex justify-center"
                style="background: #00A1B7;"
              >
                <v-col class="py-3 py-md-11 py-lg-6">
                  <v-img
                    src="/img/appbar-logo.png"
                    class="mx-auto"
                    width="241"
                    height="68"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="d-flex justify-center"
              >
                <v-col>
                  <v-img
                    class="login-image d-none d-md-block rounded-bl"
                    src="/img/login/login-img-op.jpg"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-10"
            >
              <v-row
                class=""
              >
                <v-col class=" title-text mb-1">
                  {{ $t('createAccountPage.title') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                  >
                    <validation-observer
                      ref="observer"
                      v-slot="{ invalid }"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="pb-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email|disposable"
                          >
                            <v-text-field
                              v-model="registerEmail"
                              :label="$t('loginPage.email')"
                              outlined
                              prepend-inner-icon="mdi-email-outline"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          class="pb-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            vid="password"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              ref="password"
                              v-model="registerPassword"
                              name="password"
                              :label="$t('loginPage.password')"
                              outlined
                              :type="showPassword ?'text':
                                'password'"
                              :append-icon="showPassword ?'mdi-eye-outline':'mdi-eye-off'"
                              prepend-inner-icon="mdi-lock"
                              :error-messages="errors"
                              @click:append="showPassword=!showPassword"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Confirm Password"
                            rules="required|confirmed:password"
                          >
                            <v-text-field
                              v-model="confirmPassword"
                              class="capatalize"
                              :label="$t('createAccountPage.confirmPassword')"
                              :type="showConfirmPassword ?'text':
                                'password'"
                              :append-icon="showConfirmPassword ?'mdi-eye-outline':'mdi-eye-off'"
                              prepend-inner-icon="mdi-lock"
                              :error-messages="errors"
                              outlined
                              @click:append="showConfirmPassword=!showConfirmPassword"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          class="d-flex justify-center pt-0"
                        >
                          <create-account-button
                            :button-text="$t('createAccountPage.createAccount')"
                            :button-classes="['primary', 'whtie--text', 'mr-0', 'login-btn-new']"
                            :block="'block'"
                            :large="'large'"
                            :email="registerEmail"
                            :post-signup-callback="nextStep"
                            :is-form-invalid="invalid"
                            :password="registerPassword"
                          />
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-form>
                  <!-- HIDE SOCIAL LOGINS -->
                  <v-row
                    no-gutters
                    align="center"
                    class="mt-4 d-none"
                  >
                    <v-col cols="4">
                      <v-img
                        src="/img/login/or.svg"
                        class=""
                      ></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-row
                        no-gutters
                        align="center"
                      >
                        <v-col cols="4">
                          <v-img
                            src="/img/login/g.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                        <v-col cols="4">
                          <v-img
                            src="/img/login/t.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                        <v-col cols="4">
                          <v-img
                            src="/img/login/fb.svg"
                            class="social-icon-login"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col>
                      <div class="signup-text">
                        <span style="color: #757575">
                          {{ $t('createAccountPage.bottomText1') }}
                          <a
                            class="capatalize"
                            :href="this.$store.getters.getWebsiteLocation === 'CA' ? '/privacy-ca' :'/privacy'"
                          >
                            {{ $t('cookies.privacyPolicy') }}
                          </a>
                          {{ $t('createAccountPage.bottomText2') }}
                        </span>
                        <button
                          text
                          class="primary--text"
                          @click="loginPage()"
                        >
                          {{ $t('loginPage.login') }}
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--------------------------------------MOBILE-------------------------------------->
    <!-- <v-row
      v-if="mobileDevice"
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        class="px-1"
      >
        <v-card
          class="pa-6"
          elevation="1"
          light
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <v-img
                src="/img/login-logo.svg"
                class="mx-auto"
                width="241"
                height="100"
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="form"
                @submit.prevent="submit"
              >
                <div
                  class="title-text text-center"
                >
                  <v-row class="mb-2">
                    <v-col>
                      Create an account
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <div>
                <v-form
                  ref="form"
                  @submit.prevent="submit"
                >
                  <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                  >
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="registerEmail"
                            label="Email"
                            outlined
                            append-icon="mdi-email-outline"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          vid="password"
                          name="Password"
                          rules="required"
                        >
                          <v-text-field
                            ref="password"
                            v-model="registerPassword"
                            name="password"
                            label="Password"
                            outlined
                            :type="show ?'text':
                              'password'"
                            :append-icon="show ?'mdi-eye-outline':'mdi-eye-off'"
                            :error-messages="errors"
                            @click:append="show=!show"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="9"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Confirm Password"
                          rules="required|confirmed:password"
                        >
                          <v-text-field
                            v-model="confirmPassword"
                            label="Confirm Password"
                            :type="show ?'text':
                              'password'"
                            :append-icon="show ?'mdi-eye-outline':'mdi-eye-off'"
                            :error-messages="errors"
                            outlined
                            @click:append="show=!show"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="6"
                        class="d-flex justify-center"
                      >
                        <v-btn
                          class="primary whtie--text mr-0"
                          block
                          large
                          :disabled="invalid"
                          @click="submitRegister"
                        >
                          <span class="login-btn">Register</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </validation-observer>
                </v-form>
                <v-row class="d-flex justify-center">
                  <v-col>
                    <div class="signup-text text-center">
                      <span style="color: #757575">Already have an account?</span> <button
                        text
                        class="primary--text"
                        @click="loginPage()"
                      >
                        Log in
                      </button>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->
    <otp-verification />
  </v-container>
</template>

<script>
  import CreateAccountButton from '../../components/base/CreateAccountBtn'
  import { localize } from 'vee-validate'
  export default {
    name: 'PagesRegister',
    components: {
      CreateAccountButton
    },
    // components: {
    //   PagesBtn: () => import('./components/Btn'),
    //   // PagesHeading: () => import('./components/Heading'),
    // },
    data: () => ({
      registerEmail: '',
      registerPassword: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      snackbar: false,
      timeout: 0,
      alertmessage: '',
      valid: true,
      repeatPassword: '',
      password: '',
      mobile: '',
      email: '',
      sections: [
        {
          icon: 'mdi-account-check',
          iconColor: 'primary',
          title: 'Create your account',
          text: 'Take full advantage of your Blanket'
        },
        {
          icon: 'mdi-file-document-multiple-outline',
          iconColor: 'secondary',
          title: 'Add policies',
          text: 'Add and review your policies in one place'
        },
        {
          icon: 'mdi-message',
          iconColor: 'green',
          title: 'Contact',
          text: 'Stay in touh with your Blanket agent, and get your questions answered. '
        }
      ],
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
          iconColor: '#3B5998'
        },
        {
          href: '#',
          icon: 'mdi-twitter',
          iconColor: '#1DA1F2'
        },
      ]
    }),
    computed: {
      displayError () {
        console.log('error condition is ', this.$store.getters.getErrors)
        if (this.$store.getters.getErrors) {
          this.snackbar = true
          return true
        }
        return false
      },
      errorMessage () {
        let message = ''
        message = this.$store.getters.getErrors
        if (message) {
          this.snackbar = true
        }
        return message
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      comparePasswords () {
        if (this.password !== this.repeatPassword) {
          return false
        }
        return true
      },
      loading () {
        return this.$store.getters.getloading
      }
    },
    created () {
      console.log(this.$store.getters.getPartnerId,
                  this.$store.getters.getCampaignId)
    },
    mounted () {
      localize(this.$i18n.locale)
    },
    methods: {
      loginPage () {
        this.$router.push({ path: '/pages/login' })
      },
      modalOpen (email) {
        this.$openOtpModal(email)
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      resetErrorResponse () {
        this.$store.dispatch('clearError')
        this.alertmessage = ''
        this.snackbar = false
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      },
      submit () {
        this.checking = true
        console.log('sub hit')
        this.$store.dispatch('signUserUp', {
          email: this.email.trim(),
          password: this.password
        })
        let errorstate = this.$store.getters.error
        /*
        setTimeout(() => {
          if (this.$store.getters.user) {
            console.log('WE HAVE A USER TO SEND HOME ', this.$store.getters.user)
            this.snackbar = true
            this.resetForm()
            this.$v.$reset()
            this.$router.push({
              name: 'dashboard/Dashboardv2'
            })
          }
        }, 5000)
        */
        this.checking = false
      },
      nextStep () {
        this.$router.push({ path: '/' })
        this.$gtag.event('login', { method: 'EmailPw' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.signup-text {
  font-size: 12px;
}
.social-icon-login {
  margin-left: 0.7rem;
}
.c-checkbox {
  color: #292F36 !important;
}

.login-btn-new {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
color: #292F36 !important;
background: #AAF0C1 !important;
}
.title-text {
  // font-family: 'Roboto';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 24px;
  // line-height: 32px;
  // color: #424242;
font-family: 'Sora';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: #292F36;
}
.light-grey {
  background-color: #FAFAFA
}
.capatalize{
  text-transform: capitalize;
}
.v-menu__content .theme--dark {
  background: #fff!important;
  color:#000!important
}

.v-list-item .v-list-item--link{
  color:#000!important
}
.overlay-css {
  z-index: 100001!important;
}
</style>
